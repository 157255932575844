import React from 'react';
import { Box, Card, CardActions, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import { Tutorial } from '../models/dtos/tutorial';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import StarIcon from '@mui/icons-material/Star';

type TutorialCardProps = {
  tutorial: Tutorial;
  onAddCart: (tutorial: Tutorial) => void;
};

export default function TutorialCard({ tutorial, onAddCart }: TutorialCardProps) {

  const rating = 3
  const reviews = 3

  return (
    <Card
      sx={ {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        width: '22em',
        borderRadius: '8px',
        overflow: 'hidden',
        transition: 'transform 0.3s',
        '&:hover': { transform: 'scale(1.02)' },
      } }>
      <CardMedia
        component="img"
        image={ 'https://www.r2iimmobilier.fr/wp-content/uploads/2022/09/5015_461_Deficit-foncier-2-830x350.jpg' }
        alt={ tutorial.title }
        sx={ { height: 140 } } />

      <CardContent sx={ { padding: 1, paddingBottom: '0' } }>
        <Typography
          variant="h6"
          sx={ {
            fontWeight: 'bold',
            marginBottom: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          } }>
          { tutorial.title }
        </Typography>

        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            height: '8em', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            display: '-webkit-box', 
            WebkitBoxOrient: 'vertical', 
            WebkitLineClamp: 4, 
            wordBreak: 'break-word',
            lineHeight: '1.5em', 
          }}
        >
          { tutorial.description }
        </Typography>

        <Box sx={ { display: 'flex', alignItems: 'center', marginBottom: 1 } }>

          { [ ...Array(Math.floor(rating)) ].map((_, index) => (
            <StarIcon key={ index } sx={ { color: '#FFD700', fontSize: '18px' } } />
          )) }

          { rating % 1 > 0 && <StarIcon sx={ { color: '#FFD700', fontSize: '18px' } } /> }
          <Typography variant="body2" sx={ { marginLeft: 1 } }>
            ({ reviews })
          </Typography>
        </Box>
      </CardContent>

      <CardActions sx={ { padding: '1', paddingTop: '0', justifyContent: 'space-between' } }>
        <Typography variant="h5" color="primary" sx={ { fontWeight: 'bold' } }>
          { tutorial.price } €
        </Typography>

        <IconButton color="primary" onClick={ () => onAddCart(tutorial) }>
          <AddShoppingCartOutlinedIcon fontSize={ 'large' } />
        </IconButton>
      </CardActions>
    </Card>
  );
}


// import React from 'react'
// import { Box, Card, CardActions, CardContent, CardHeader, CardProps, Collapse, IconButton, IconButtonProps, styled, Typography } from '@mui/material'
// import { Tutorial } from '../models/dtos/tutorial'
// import FavoriteIcon from '@mui/icons-material/Favorite'
// import ShareIcon from '@mui/icons-material/Share'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import MoreVertIcon from '@mui/icons-material/MoreVert'
// import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined'
//
// type TutorialCardProps = {
//   tutorial: Tutorial
//   onAddCart: (tutorial: Tutorial) => void
// } & CardProps
//
// interface ExpandMoreProps extends IconButtonProps {
//   expand: boolean
// }
//
// export default function TutorialCard(props: TutorialCardProps) {
//   const [ expanded, setExpanded ] = React.useState(false)
//
//   const handleExpandClick = () => {
//     setExpanded(!expanded)
//   }
//
//   const ExpandMore = styled((props: ExpandMoreProps) => {
//     const { expand, ...other } = props
//     return <IconButton { ...other } />
//   })(({ theme }) => ({
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//       duration: theme.transitions.duration.shortest,
//     }),
//     variants: [
//       {
//         props: ({ expand }) => !expand,
//         style: {
//           transform: 'rotate(0deg)',
//         },
//       },
//       {
//         props: ({ expand }) => !!expand,
//         style: {
//           transform: 'rotate(180deg)',
//         },
//       },
//     ],
//   }))
//
//
//   return (
//     <Card
//       sx={ {
//         boxShadow: 'none',
//         display: 'block',
//         width: '25em',
//         height: '12em',
//         transitionDuration: '0.3s'
//       } }>
//       <CardHeader
//         title={ props.tutorial.title }
//         action={
//           <IconButton aria-label="settings">
//             <MoreVertIcon />
//           </IconButton>
//         }
//       />
//       <CardContent>
//         <Typography variant="body2" sx={ { color: 'text.secondary' } }>
//           { props.tutorial.description }
//         </Typography>
//       </CardContent>
//       <CardActions disableSpacing>
//         <IconButton aria-label="add to favorites" onClick={ _ => props.onAddCart(props.tutorial) }>
//           <AddShoppingCartOutlinedIcon />
//         </IconButton>
//         <IconButton aria-label="share">
//           <ShareIcon />
//         </IconButton>
//         <ExpandMore
//           expand={ expanded }
//           onClick={ handleExpandClick }
//           aria-expanded={ expanded }
//           aria-label="show more"
//         >
//           <ExpandMoreIcon />
//         </ExpandMore>
//       </CardActions>
//       <Collapse in={ expanded } timeout="auto" unmountOnExit>
//         <CardContent>
//           <Typography sx={ { marginBottom: 2 } }>Description:</Typography>
//           <Typography sx={ { marginBottom: 2 } }>
//             Ce tutoriel vous guide pas à pas dans la déclaration de vos revenus locatifs.
//             Découvrez comment remplir correctement chaque champ, éviter les erreurs courantes
//             et maximiser vos déductions fiscales. Idéal pour les propriétaires ou investisseurs
//             immobiliers souhaitant optimiser leur déclaration d'impôts.
//           </Typography>
//           <Typography sx={ { marginBottom: 2 } }>
//             Vous apprendrez à déclarer vos revenus, comprendre les règles fiscales actuelles,
//             et utiliser les outils à votre disposition pour calculer vos impôts de manière optimale.
//           </Typography>
//           <Typography>
//             Ce guide pratique vous aidera à simplifier vos démarches administratives tout en restant conforme à la législation.
//           </Typography>
//         </CardContent>
//       </Collapse>
//     </Card>
//   )
// }